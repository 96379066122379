import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import ChartLegend from '../ChartLegend';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';

type Props = {
  apiResponse: any;
};

const IndividualCTRBarChart = ({ apiResponse }: Props) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState<any[]>([]);

  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  const getConversionRateData = async (res: any) => {
    setLoading('pending');
    if (res?.type === 'individual' && res?.data) {
      if (res.data?.length === 0) {
        setLoading('null');
        return;
      }

      const newChartData = res.data?.map((item: any) => {
        return {
          label: item.button_color,
          value: item.ctr,
          color: item.button_color,
        };
      });

      // Set chart data once
      setChartData(newChartData);
      setLoading('success');
    }
  };

  useEffect(() => {
    if (apiResponse) {
      getConversionRateData(apiResponse);
    }
  }, [apiResponse]);

  const valueFormatter = (value: number | null) => `${value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}

      {loading === 'null' && (
        <NoData
          title={t('No data available')}
          subTitle={t('NeedMoreInteractionsToGenerateStats')}
        />
      )}
      {loading === 'success' && (
        <>
          <div className='chart__bottom--content-chart'>
            <div
              style={{ width: '100%', marginRight: '0' }}
              className='chart__bottom--content-chart-pie'
            >
              <BarChart
                dataset={chartData}
                xAxis={[
                  {
                    scaleType: 'band',
                    dataKey: 'color',
                    label: 'Color',
                  },
                ]}
                yAxis={[
                  {
                    label: 'CTR (%)',
                    min: 0,
                    max: 100,
                  },
                ]}
                series={[{ dataKey: 'value', valueFormatter }]}
                colors={['#8884d8']}
                sx={(theme) => ({
                  [`.${axisClasses.root}`]: {
                    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
                      stroke: '#8b909a',
                      strokeWidth: 3,
                    },
                    [`.${axisClasses.tickLabel}`]: {
                      fill: '#8b909a',
                    },
                  },
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    fill: '#8b909a',
                    // transform: 'translate(-10px, -10px)',
                  },
                  [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                    fill: '#8b909a', // Change color of x-axis label
                  },
                  [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                    fill: '#8b909a', // Change color of x-axis tick labels
                  },
                  border: '1px solid #8b909a25',
                  backgroundImage:
                    'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
                  backgroundSize: '50px 50px',
                  backgroundPosition: '0px 0px, 0px 0px',
                  ...theme.applyStyles('dark', {
                    borderColor: '#8b909a25',
                    backgroundImage:
                      'linear-gradient(#8b909a25 1px, transparent 1px), linear-gradient(90deg, #8b909a25 1px, transparent 1px)',
                  }),
                })}
              />
            </div>
          </div>
          <div
            data-is-chart-legend-row={'true'}
            className='chart__bottom--content-chart-legend'
          >
            <div className='chart__bottom--content-chart-legend-content'>
              {chartData?.map((item, index) => (
                <ChartLegend
                  key={index}
                  color={item?.color}
                  label={item?.label}
                  value={item?.value}
                  symbol='%'
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IndividualCTRBarChart;
