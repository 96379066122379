import '../../styles/components/charts/chart.scss';

type Props = {
  color: string;
  label: string;
  value?: number;
  symbol?: string;
  onClick?: () => void;
};

const ChartLegend = ({ color, label, value, symbol, onClick }: Props) => {
  return (
    <div onClick={onClick} className='chart__bottom--content-chart-legend-item'>
      <span
        className='chart__bottom--content-chart-legend-item-color'
        style={{ backgroundColor: color }}
      ></span>
      <span className='chart__bottom--content-chart-legend-item-text'>
        {`${label} ${
          value !== undefined && value !== null
            ? `(${value}${symbol ? symbol : ''})`
            : ''
        }`}
      </span>
    </div>
  );
};

export default ChartLegend;
