const colorsForCharts = [
  '#2f26d8',
  '#081e66',
  '#126e7a',
  '#200d63',
  '#6b6aaf',
  '#0000FF',
  '#48a7be',
  '#0066FF',
  '#0033FF',
  '#0099FF',
  '#00CCFF',
  '#00FFFF',
  '#33FFFF',
  '#66FFFF',
  '#99FFFF',
  '#CCFFFF', // Light Blues
  '#6699FF',
  '#3366FF',
  '#0033CC',
  '#002699',
  '#001966', // Deep Blues
  '#6600FF',
  '#6600CC',
  '#6633FF',
  '#6666FF',
  '#9966FF',
  '#9966CC', // Purples
  '#9933FF',
  '#9900FF',
  '#CC33FF',
  '#CC00FF',
  '#CC66FF',
  '#CC99FF',
  '#FF33FF',
  '#FF00FF', // Magentas
  '#FF66FF',
  '#FF99FF',
  '#FF66CC',
  '#FF3399',
  '#FF0099',
  '#FF0066',
  '#FF0033',
  '#FF3300',
  '#FF6600', // Pinks to Oranges
  '#FF9900',
  '#FFCC00',
  '#FFFF00',
  '#FFFF33',
  '#FFFF66',
  '#FFFF99',
  '#FFFFCC', // Yellows
  '#99FF66',
  '#66FF33',
  '#33FF00',
  '#00FF00',
  '#00FF33',
  '#00FF66',
  '#00FF99',
  '#00FFCC',
  '#33FFCC', // Greens
  '#66FF99',
  '#99FFCC',
  '#CCFF99',
  '#CCFF66',
  '#99FF33',
  '#99FF00',
  '#CCFF00', // Lime Greens
  '#FFCC33',
  '#FFCC66',
  '#FFCC99',
  '#FFCC99',
  '#FF9966',
  '#FF9933',
  '#FF6600', // Oranges
  '#FF3333',
  '#FF0000',
  '#FF0033',
  '#CC0000',
  '#990000',
  '#660000', // Reds
  '#993333',
  '#996666',
  '#999999',
  '#CCCCCC',
  '#E0E0E0',
  '#F0F0F0',
  '#F5F5F5',
  '#FAFAFA', // Grays and Neutrals
  '#6666FF',
  '#3399FF',
  '#00CCCC',
  '#00BFFF',
  '#3399CC',
  '#9966FF',
  '#9933CC',
  '#6633FF',
  '#3300FF', // Extra Blues and Purples
];

export default colorsForCharts;
