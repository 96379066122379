import { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import CssClasses from './CssClasses/CssClasses';
import Integration from './Integration/Integration';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import getCurrentRoute from '../../../lib/helper-functions/getCurrentRoute';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import get_checkout_tracking_api_call from '../../../lib/api/integration/get_checkout_tracking_api_call';
import post_integration_api_call from '../../../lib/api/integration/post_integration_api_call';
import Trends from './Trends/Trends';
import { allRoutes, HOME_ROUTE } from '../../../lib/constants/route';
import Autofill from './Autofill/Autofill';

interface ApiDataType {
  api_key: string;
  [key: string]: any;
}

function CheckoutTracking() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('null');
  const [key, setKey] = useState<string>('');
  const [isTrackable, setIsTrackable] = useState<boolean>(false);
  const [apiData, setAPIData] = useState<ApiDataType[] | null>(null);
  const [isAutofillEnabled, setIsAutofillEnabled] = useState<boolean>(false);
  const { t } = useTranslation();

  const location = useLocation();
  const currentRoute = getCurrentRoute(location);

  useEffect(() => {
    if (
      currentRoute === 'manage' ||
      currentRoute === 'trends' ||
      currentRoute === 'autofill'
    )
      getAvailableTrackingData();
  }, [currentRoute]);

  useEffect(() => {
    if (currentRoute === 'integration' && key === '') {
      getAvailableTrackingData();
    }
  }, [currentRoute, key]);

  const getAvailableTrackingData = async () => {
    if (currentRoute === 'trends') {
      setLoading('success');
      return;
    }
    setLoading('pending');
    try {
      const res: ApiDataType[] = await get_checkout_tracking_api_call();

      if (!res || res.length === 0) {
        const sendingData = {
          name: 'checkout-tracking-class-names',
          type: 'tracker',
          configuration: { key: 'value' },
        };
        const postRes = await post_integration_api_call(sendingData, undefined);
        if (postRes && postRes.api_key) {
          setKey(postRes.api_key);
        }
        setIsTrackable(false);
      } else {
        if (
          res[res.length - 1]?.configuration &&
          JSON.stringify(res[res.length - 1].configuration) ===
            JSON.stringify({ key: 'value' })
        ) {
          setIsTrackable(false);
        } else {
          setIsTrackable(true);
        }
        setAPIData(res);
        if (res?.length > 0) {
          const additionalConfigs = res[res?.length - 1]?.additional_configs;

          if (typeof additionalConfigs?.is_autofill_enabled === 'string') {
            setIsAutofillEnabled(
              additionalConfigs?.is_autofill_enabled === 'true' ? true : false
            );
          } else {
            setIsAutofillEnabled(false);
          }
        }
        setKey(res[res.length - 1]?.api_key || '');
      }
      setLoading('success');
    } catch (error) {
      console.error('Error fetching tracking data:', error);
      setLoading('error');
    }
  };

  const subTitle =
    currentRoute === 'manage'
      ? t('ManageTrackingClassNames')
      : currentRoute === 'integration'
      ? t('CheckoutTrackingIntegration')
      : currentRoute === 'autofill'
      ? t('ManageAutofill')
      : currentRoute === 'trends'
      ? t('Recommendations')
      : t('ManageCheckoutTracking');

  return (
    <SubPageInnerContentHolder
      title={t('CheckoutTracking')}
      subTitle={subTitle}
      // backBtnLink={HOME_ROUTE}
    >
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}

      <Routes>
        {allRoutes['/tracking/css-classes/manage'] && (
          <Route
            path='manage/*'
            element={
              loading === 'success' ? (
                <CssClasses
                  data={apiData}
                  setScriptKey={setKey}
                  isAutofillEnabled={isAutofillEnabled}
                />
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/autofill'] && (
          <Route
            path='autofill/*'
            element={
              loading === 'success' ? (
                <Autofill
                  setIsAutofillEnabled={setIsAutofillEnabled}
                  isAutofillEnabled={isAutofillEnabled}
                  data={apiData}
                  setScriptKey={setKey}
                />
              ) : null
            }
          />
        )}
        {allRoutes['/tracking/css-classes/integration'] && (
          <Route
            path='integration/*'
            element={key ? <Integration apiKey={key} /> : null}
          />
        )}
        {allRoutes['/tracking/css-classes/trends'] && (
          <Route
            path='trends/*'
            element={
              loading === 'success' ? (
                <Trends apiKey={key} isTrackable={isTrackable} />
              ) : null
            }
          />
        )}
      </Routes>
    </SubPageInnerContentHolder>
  );
}

export default CheckoutTracking;
